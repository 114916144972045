.container {
  position: relative;
  .videoPlayer {
    height: 200px;
  }

  .videoBigPlayer {
    height: 380px;
  }

  .videoOverlay {
    position: absolute;
    height: 380px;
    width: 100%;
    background: none;
    pointer-events: none;
    top: 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .roadInfo,
    .roadId,
    .chainage {
      width: 40%;
      background-color: rgba(173, 173, 173, 0.45);
      color: white;
      text-align: center;
      padding: 8px;
      height: 32px;
    }
  }
}
