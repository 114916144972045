.coinCollectorItem {
  height: 15px;
  width: auto;
}

.root {
  width: '100%';
}

.paper {
  width: '100%';
  margin-bottom: 20px;
}
