@import "../../../style/_variables.scss";

.formControl{
  min-width: 240px !important;
}

.root{
  div[class*="materialui-daterange-picker-MuiPaper"] {
    box-shadow: none;
  }
}
.button{
  margin: 16px 24px !important;
}