.datepicker{
    padding: 20px 0;
}

.modal {
    position: absolute;
    width: 400px;
    .paper{
        padding: 20px;
    }
}


.root{
    width: '100%';
}

.paper{
    width: '100%';
    margin-bottom: 20px;
}

.table{
    width: 100%;
}