// colors

$white: #ffffff;
$black: #000000;

$main1: #3f51b5;

$green: #23ca98;
$red: #f52d54;
$light_blue: #2e6cb7;
$dark_blue: #11387f;
$darkest_blue: #132e6e;

// bootstrap breakpoints
$xxs: '(max-width: 399px)';
$xs: '(max-width: 599.98px)';
$xs-landscape: '(max-height: 399px)';
$sm: '(max-width: 959.98px)';
$sm-landscape: '(min-width: 600px) and (max-width: 959.98px) and (max-height: 600px)';
$md: '(max-width: 1199.98px)';
$lg: '(min-width: 1199.98px)';
$xl: '(min-width: 1919px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}
