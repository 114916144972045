@import '../../../style/_variables.scss';

.paper {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px;
  border: 2px solid transparent;
}

.content {
  gap: 16px;
  padding: 8px;
  .videoPlayer {
    height: 200px;
  }
  .videoInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .videoInfoRow {
      display: flex;
      gap: 16px;
    }
  }
  .videoActions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.selectedVideo {
  border: 2px solid #3f51b5;
}
