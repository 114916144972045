.root {
  width: '100%';
}

.paper {
  width: '100%';
  margin-bottom: 20px;
}

.table {
  width: 100%;
}

.modal {
  position: absolute;
  width: 400px;
  .paper {
    padding: 20px;
  }
}
.formContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}
.adminForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  input {
    margin: 2%;
    flex: 1 0 40%;
    padding: 1%;
  }
}

.submitButton {
  margin: 2%;
}
