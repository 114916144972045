.paperContainer {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px;
}

.parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.projectItem {
  margin: 15px;
}

.renderedItems {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.pagination {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.button {
  width: 20em;
  margin: auto !important;
}
