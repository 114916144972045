.infoText {
  font-size: 1.5em;
}

.root {
  .generalInfo {
    gap: 16px;
    padding: 8px;
    .videoInfo {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .videoInfoRow {
        display: flex;
        gap: 16px;
      }
    }
    .videoMapSection {
      position: relative;
      height: 400px;
      .videoPlayer {
        width: 48%;
      }
    }
    .videoActions {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      .actionButtons {
        display: flex;
        align-items: center;
        gap: 40px;
        span {
        }
      }
    }
  }


}

.collectablesData {
  display: flex;
  flex-direction: row !important;
  gap: 16px;
  justify-content: space-evenly;
  align-items: center;
}

.line {
  height: 30px;
  width: 1px;
  background: rgba(0, 0, 0, 0.3);
}
