.datepicker{
    padding: 20px 0;
}

.root{
    width: '100%';
}

.paper{
    width: '100%';
    margin-bottom: 20px;
}