.root {
  .notificationForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .sendButton {
      width: 100%;
      .submitButton {
        width: 100%;
      }
    }
  }
}

.messagebox {
  max-width: min-content;
  min-width: 600px;
}

.textArea {
  font-family: 'Roboto';
}
