.infoText {
    font-size: 1.5em;
}

.root {
    .generalInfo {
        grid-column: 1/3;
        .fullName {
            font-size: 3em;
        }
        .username {
            @extend .infoText;
        }
        .email {
            @extend .infoText;
        }
        .videoStats {
            display: flex;
            gap: 32px;
            span {
                @extend .infoText;
            }
        }
    }

    .pendingVideos {
        grid-column: 1/2;
    }

    .approvedVideos {
        grid-column: 2/3;
    }

    .allVideos {
        grid-column: 1/3;
    }

    .payments {
        grid-column: 1/3;
    }
}
