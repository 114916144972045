.root {
  width: '100%';
}

.paper {
  width: '100%';
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.inputs {
  width: 100%;
}

.modal {
  padding: 20px;
}

.button {
  width: 20rem;
}
