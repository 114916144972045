@import '../../../style/_variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 60vh;
  border-radius: 4px;
  box-shadow: 0 0 24px 0 rgba(20, 38, 72, 0.05);
  background-color: $white;
  padding: 24px;
  .actions {
    margin-left: auto;
    align-self: center;
  }

  @media (min-width: 992px) {
    width: 45%;
  }
}

.collectablesHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px 0 15px;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.header {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  width: 20em;
  justify-content: space-between;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.title {
  font-size: 2em;
  cursor: pointer;
}

.projectDescription {
  padding-bottom: 15px;
}

.button {
  margin-top: 25px !important;
}
