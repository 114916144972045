@import "../../../style/_variables.scss";

.simpleLayout {
  height: max-content;
  width: 100%;
  display: grid;
  //grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  //grid-template-rows: minmax(80px, 1fr);
  grid-auto-rows: minmax(32px, auto);
  gap: 24px;
  padding: 24px;
}
