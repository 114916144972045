.outter{
    display: flex;
    position: absolute;
    left: 50%;
    right: auto;
    bottom: 24px;
    transform: translateX(-50%);
    flex-direction: column;
    grid-gap: 20px;
    gap: 12px;
    z-index: 9999;
}

.test{
    width: 100%;
}

.root{
    position: relative !important;
    margin-top: 5px;
    width: 60vw;
    max-width: 700px;
}