@import "../../../style/_variables.scss";

.root {
}

.formControl {
  min-width: 240px !important;
}

.paper{
  box-shadow: none !important;
}

.gap {
  gap: 16px;
  display: flex;
  padding: 6px 16px 8px 16px;
}